import React from 'react';
import { Box } from '@heycater/design-system';
import Image, { StaticImageData } from 'next/image';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import bbqIcon from '@images/shared/catering_category/tile_icons/bbq.webp';
import breakfastIcon from '@images/shared/catering_category/tile_icons/breakfast.webp';
import buffetIcon from '@images/shared/catering_category/tile_icons/buffet.webp';
import sweetsIcon from '@images/shared/catering_category/tile_icons/cake.webp';
import christmasIcon from '@images/shared/catering_category/tile_icons/christmas.webp';
import dinnerIcon from '@images/shared/catering_category/tile_icons/dinner.webp';
import drinksIcon from '@images/shared/catering_category/tile_icons/drinks.webp';
import fingerfoodIcon from '@images/shared/catering_category/tile_icons/fingerfood.webp';
import lunchIcon from '@images/shared/catering_category/tile_icons/lunch.webp';
import streetFoodIcon from '@images/shared/catering_category/tile_icons/tacos.webp';
import { EXPERIMENT } from 'lib/ablyft';
import { useBreakpointUp } from 'qualification/hooks/useBreakpoints';
import { useVariationActive } from 'qualification/hooks/useVariationActive';
import { CateringCategoryValue } from 'qualification/schema/questions/cateringCategoriesQuestion';
import {
  Tile,
  TilesWrapper,
  TileTypography,
} from 'shared/components/Tiles/BaseComponents';
import QuestionMark from 'shared/svg/QuestionMark';
import CateringCategoriesTitlesVariationA from 'static_pages/home/variationA/components/CateringCategoriesTitles';

type Props = {
  items?: CateringCategoryValue[];
  onSelect: (category: CateringCategoryValue) => void;
  selected?: CateringCategoryValue[];
  variant?: 'primary' | 'secondary';
};

const DEFAULT_IMAGE_SIZE = {
  SM: 54,
  MD: 84,
};

const IMAGE_WRAPPER_SIZE = {
  SM: 70,
  MD: 124,
};

const CateringCategoryTiles = ({
  onSelect,
  selected,
  variant = 'primary',
  items,
}: Props) => {
  const { t } = useTranslation('common');
  const cateringCategories = useCateringCategories(items);
  const smUp = useBreakpointUp('sm');

  const isHomepageVariationB = useVariationActive(
    EXPERIMENT.homePage.variations.variationB
  );

  if (isHomepageVariationB)
    return (
      <CateringCategoriesTitlesVariationA
        onSelect={onSelect}
        selected={selected}
        variant={variant}
        items={items}
      />
    );

  return (
    <TilesWrapper>
      {cateringCategories.map(({ src, text, scale, id }) => (
        <Tile
          $variant={variant}
          $selected={selected ? selected.includes(id) : false}
          id={`tile__catering_category--${id}`}
          key={id}
          onClick={(e) => {
            e.currentTarget.blur();
            onSelect(id);
          }}
        >
          <ImageWrapper $scale={scale}>
            <Image
              alt=""
              width={smUp ? DEFAULT_IMAGE_SIZE.MD : DEFAULT_IMAGE_SIZE.SM}
              height={smUp ? DEFAULT_IMAGE_SIZE.MD : DEFAULT_IMAGE_SIZE.SM}
              src={src}
              objectFit="cover"
            />
          </ImageWrapper>
          <TileTypography>{text}</TileTypography>
        </Tile>
      ))}
      <Tile
        $variant={variant}
        onClick={(e) => {
          e.currentTarget.blur();
          onSelect('other');
        }}
        $selected={selected ? selected?.includes('other') : false}
      >
        <ImageWrapper
          opacity={0.4}
          fontSize={{ xs: 28, sm: 84 }}
          pt={{ xs: 2, sm: 0 }}
          pb={{ xs: 1, sm: 0 }}
        >
          <QuestionMark height="1em" />
        </ImageWrapper>
        <TileTypography>{t('labels.notSure')}</TileTypography>
      </Tile>
    </TilesWrapper>
  );
};

const ImageWrapper = styled(Box)<{ opacity?: number; $scale?: number }>`
  --wrapperSize: ${IMAGE_WRAPPER_SIZE.SM}px;

  border-radius: 8px 8px 0 0;
  transform: scale(${({ $scale }) => $scale || 1});
  height: var(--wrapperSize);
  width: var(--wrapperSize);
  position: relative;
  display: grid;
  place-content: center;
  opacity: ${({ opacity }) => opacity || 1};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    --wrapperSize: ${IMAGE_WRAPPER_SIZE.MD}px;
    width: 100%;
  }
`;

interface CategoryItem {
  id: CateringCategoryValue;
  src: StaticImageData;
  text: string;
  scale?: number;
}

function useCateringCategories(items?: CateringCategoryValue[]) {
  const { t } = useTranslation('common');

  const allCategories: CategoryItem[] = [
    {
      id: 'fingerfood',
      src: fingerfoodIcon,
      text: t('occasion.fingerfood'),
    },
    {
      id: 'buffet',
      src: buffetIcon,
      text: t('occasion.buffet'),
    },
    {
      id: 'street_food',
      src: streetFoodIcon,
      text: t('occasion.street_food'),
      scale: 1.15,
    },
    {
      id: 'business_lunch',
      src: lunchIcon,
      text: t('occasion.business_lunch'),
    },
    {
      id: 'christmas',
      src: christmasIcon,
      text: t('occasion.christmas'),
      scale: 1.1,
    },
    {
      id: 'bbq',
      src: bbqIcon,
      text: t('occasion.bbq'),
    },
    {
      id: 'breakfast',
      src: breakfastIcon,
      text: t('occasion.breakfast'),
    },
    {
      id: 'cakes_and_sweets',
      src: sweetsIcon,
      text: t('occasion.cakes_and_sweets'),
    },
    {
      id: 'fine_dining',
      src: dinnerIcon,
      text: t('occasion.fine_dining_short'),
    },
    {
      id: 'drinks',
      src: drinksIcon,
      text: t('occasion.drinks_short'),
    },
  ];

  const preFilteredCategories = allCategories.filter(({ id }) => id !== 'bbq'); // Remove seasonal entries

  if (items) {
    return preFilteredCategories.filter((category) =>
      items.includes(category.id)
    );
  }

  return preFilteredCategories;
}

export default CateringCategoryTiles;
