import styled, { css } from 'styled-components';

export const Tile = styled.div<{
  $selected: boolean;
  $variant: 'primary' | 'secondary';
  $height?: string;
}>`
  ${({ theme, $variant }) =>
    $variant === 'primary' &&
    css`
      --active-background: #fff9f4;
      --active-outline: 3px solid ${theme.palette.primary.main};
      --active-text-color: rgba(0, 0, 0, 0.7);
    `}

  ${({ theme, $variant }) =>
    $variant === 'secondary' &&
    css`
      --active-background: white;
      --active-outline: 2px solid ${theme.palette.secondary[500]};
      --active-text-color: ${theme.palette.secondary[500]};
    `}
 
  --active-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.15);

  box-sizing: content-box;
  cursor: pointer;
  background: #fff;
  display: flex;
  overflow: hidden;
  align-items: center;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing(1)}px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  border-radius: 8px;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  gap: ${({ theme }) => theme.spacing(3)}px;
  height: 90px;
  width: 90px;
  justify-content: flex-start;

  justify-content: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    justify-content: center;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)}px;
    width: 154px;
    height: ${({ $height }) => $height || '166px'};

    padding: ${({ theme }) => theme.spacing(1, 1, 2, 1)};
  }

  outline: ${({ $selected }) => ($selected ? 'var(--active-outline)' : 'none')};
  box-shadow: ${({ $selected }) =>
    $selected ? 'var(--active-shadow)' : '0px 4px 14px 0px rgba(0, 0, 0, 0.1)'};
  color: ${({ $selected }) =>
    $selected ? 'var(--active-text-color)' : 'rgba(0, 0, 0, 0.7)'};
  background-color: ${({ $selected }) =>
    $selected ? 'var(--active-background)' : 'white'};

  transition: all 0.1s ease;

  p {
    width: fill-available;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: clip;
    text-align: center;
  }

  @media (hover: hover) {
    &:hover {
      outline: var(--active-outline);
      box-shadow: var(--active-shadow);
      color: var(--active-color);
    }
  }

  :focus,
  :active {
    outline: var(--active-outline);
    box-shadow: var(--active-shadow);
    color: var(--active-text-color);
    background-color: var(--active-background);
  }
`;
