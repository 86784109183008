import React, { useState } from 'react';
import { Box, Typography } from '@heycater/design-system';
import { IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import breakfastIcon from '@images/shared/catering_category/tile_icons/variationA/breakfast.png';
import buffetIcon from '@images/shared/catering_category/tile_icons/variationA/buffet.png';
import christmasIcon from '@images/shared/catering_category/tile_icons/variationA/christmas.png';
import dinnerIcon from '@images/shared/catering_category/tile_icons/variationA/dinner.png';
import drinksIcon from '@images/shared/catering_category/tile_icons/variationA/drinks.png';
import fingerfoodIcon from '@images/shared/catering_category/tile_icons/variationA/fingerfood.png';
import streetFoodIcon from '@images/shared/catering_category/tile_icons/variationA/foodtruck.png';
import lunchIcon from '@images/shared/catering_category/tile_icons/variationA/lunch.png';
import sweetsIcon from '@images/shared/catering_category/tile_icons/variationA/sweets.png';
import { useBreakpointUp } from 'qualification/hooks/useBreakpoints';
import {
  TilesWrapper,
  TileTypography,
} from 'shared/components/Tiles/BaseComponents';
import { Tile } from 'shared/components/Tiles/variationA/BaseComponents';
import QuestionMark from 'shared/svg/QuestionMark';

const DEFAULT_IMAGE_SIZE = {
  SM: 66,
  MD: 123,
};

const IMAGE_WRAPPER_SIZE = {
  SM: 66,
  MD: 124,
};

const CateringCategoryTiles = ({
  onSelect,
  selected,
  variant = 'primary',
  items,
}) => {
  const { t } = useTranslation('common');
  const cateringCategories = useCateringCategories(items);
  const smUp = useBreakpointUp('sm');
  const [loadAll, setLoadAll] = useState(false);

  const renderedCateringCategories = () => {
    if (smUp || loadAll) {
      return cateringCategories;
    }
    return cateringCategories.splice(0, 6);
  };

  return (
    <>
      <TilesWrapper>
        {renderedCateringCategories().map(({ src, text, scale, id }) => (
          <Tile
            $variant={variant}
            $selected={selected ? selected.includes(id) : false}
            id={`tile__catering_category--${id}`}
            key={id}
            onClick={(e) => {
              e.currentTarget.blur();
              onSelect(id);
            }}
          >
            <ImageWrapper $scale={scale}>
              {id === 'other' ? (
                <ImageWrapper
                  opacity={0.4}
                  fontSize={{ xs: 28, sm: 84 }}
                  pt={{ xs: 2, sm: 0 }}
                  pb={{ xs: 1, sm: 0 }}
                >
                  <QuestionMark height="1em" />
                </ImageWrapper>
              ) : (
                <Image
                  alt=""
                  width={smUp ? DEFAULT_IMAGE_SIZE.MD : DEFAULT_IMAGE_SIZE.SM}
                  height={smUp ? DEFAULT_IMAGE_SIZE.MD : DEFAULT_IMAGE_SIZE.SM}
                  src={src}
                  objectFit="cover"
                />
              )}
            </ImageWrapper>
            <TileTypography>{text}</TileTypography>
          </Tile>
        ))}
      </TilesWrapper>
      {smUp || loadAll ? null : (
        <Box display="flex" justifyContent="center">
          <ShowMoreButton onClick={() => setLoadAll(true)}>
            <Typography>{t('labels.showMore')}</Typography>
            <ExpandMoreIcon />
          </ShowMoreButton>
        </Box>
      )}
    </>
  );
};

const ImageWrapper = styled(Box)`
  --wrapperSize: ${IMAGE_WRAPPER_SIZE.SM}px;

  border-radius: 8px 8px 0 0;
  transform: scale(${({ $scale }) => $scale || 1});
  height: var(--wrapperSize);
  width: var(--wrapperSize);
  position: relative;
  display: grid;
  place-content: center;
  opacity: ${({ opacity }) => opacity || 1};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    --wrapperSize: ${IMAGE_WRAPPER_SIZE.MD}px;
    width: 100%;
  }
`;

const ShowMoreButton = styled(IconButton)`
  p {
    font-size: 12px;
    font-weight: 600;
    line-height: 20.7px;
    text-align: center;
  }
  svg {
    height: 24px;
    width: 24px;
    font-size: 24px;
  }
`;

function useCateringCategories() {
  const { t } = useTranslation('common');

  const allCategories = [
    {
      id: 'business_lunch',
      src: lunchIcon,
      text: t('occasion.variationA.business_lunch'),
    },
    {
      id: 'fingerfood',
      src: fingerfoodIcon,
      text: t('occasion.fingerfood'),
    },
    {
      id: 'buffet',
      src: buffetIcon,
      text: t('occasion.buffet'),
    },
    {
      id: 'street_food',
      src: streetFoodIcon,
      text: t('occasion.street_food'),
      scale: 1.15,
    },
    {
      id: 'christmas',
      src: christmasIcon,
      text: t('occasion.christmas'),
      scale: 1.1,
    },
    {
      id: 'fine_dining',
      src: dinnerIcon,
      text: t('occasion.fine_dining_short'),
    },
    {
      id: 'breakfast',
      src: breakfastIcon,
      text: t('occasion.breakfast'),
    },
    {
      id: 'cakes_and_sweets',
      src: sweetsIcon,
      text: t('occasion.cakes_and_sweets'),
    },
    {
      id: 'drinks',
      src: drinksIcon,
      text: t('occasion.drinks_short'),
    },
    {
      id: 'other',
      src: <QuestionMark height="1em" />,
      text: t('labels.notSure'),
    },
  ];

  return allCategories;
}

export default CateringCategoryTiles;
